import React, { useEffect, useState } from "react";
import styles from "./Navbar.module.css";
import AppBar from "@mui/material/AppBar";
// import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
// import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { ChatState } from "../../context/Context";

export default function Navbar() {
  //  --------------------
  const { menu_clicked, navbarView, setMenuClicked } = ChatState();
  const menuClicked = () => {
    setMenuClicked(!menu_clicked);
    console.log("menu clicked = ", menu_clicked);
  };

  const homeClickHandler = () => {
    window.scrollTo({
      top: 0,
      behaviour: 'smooth'
    })
  }

  const useCaseClickHandler = () => {
    const section = document.querySelector("#infoSection");
    if (section) {
      section.scrollIntoView({ behaviour : "smooth"})
    }
  }
  const productClickHandler = () => {
    const section = document.querySelector("#videoSection");
    if (section) {
      section.scrollIntoView({ behaviour : "smooth"})
    }
  }

  //  --------------------
  let value = 0;
  useEffect(() => {
    const scrollNavbar = () => {
      let scroll = document.querySelector(`.${styles.navbarContainer}`);
      let Navbarbox = document.querySelector(`.${styles.Navbarbox}`);

      // checking whether usp section is in viewport
      const allFalse = navbarView.uspView || navbarView.videoSectionView;

      if (window.scrollY >= 300) {
        if (window.scrollY > value || allFalse) {
          scroll.style.transform = "translateY(-100%)";
          setMenuClicked(false);
          Navbarbox.classList.remove(`${styles.hovered}`);
        } else if (!allFalse) {
          setTimeout(function () {
            if (window.scrollY >= 300) {
              Navbarbox.classList.add(`${styles.hovered}`);
            }
          }, 1000);
          scroll.style.transform = "translateY(0)";
        }
        value = window.scrollY;
      } else {
        Navbarbox.classList.remove(`${styles.hovered}`);
        scroll.style.transform = "translateY(0)";
      }
    };

    const hamburgerMenuScroll = () => {
      let target = document.querySelector(`.${styles.buttonholder__menu}`)
      if (window.innerHeight <= window.scrollY) {
        target.style.background = 'rgba(39, 42, 44, 0.5)'
        target.style.color = '#3ED2DC'
        for (let i = 0; i < target.children.length; i++) {
          target.children[i].style.textShadow = '0px 2px 3px black'
        }
      } else {
        target.style.background = 'rgba(255, 250, 250, 0.18)'
        target.style.color = 'rgba(32, 236, 247, 1)'
        for (let i = 0; i < target.children.length; i++) {
          target.children[i].style.textShadow = 'none'
        }
      }
    }

    document.addEventListener("scroll", hamburgerMenuScroll)
    document.addEventListener("scroll", scrollNavbar);
    return () => {
      document.removeEventListener("scroll", scrollNavbar);
      document.removeEventListener("scroll", hamburgerMenuScroll)
    }
  }, []);

  return (
    <AppBar position="fixed" className={styles.navbarContainer}>
      <Toolbar className={styles.Navbarbox}>
        <div className={styles.logowrapper}>
          <div className={styles.logo} />
        </div>
        <div className={styles.buttonholder}>
          <Button onClick={homeClickHandler} className={styles.holdertext} color="inherit">
            Home
          </Button>
          <Button onClick={productClickHandler} className={styles.holdertext} color="inherit">
            Product
          </Button>
          <Button onClick={useCaseClickHandler} className={styles.holdertext} color="inherit">
            UseCase
          </Button>
        </div>
        <div className={styles.sidepush}>
          <ul className={styles.navList}>
            {/* <Link className={styles.linkd} to="/blog">
              BLOG
            </Link> */}
            {/* -------------------------- */}
            <li className={styles.flex__props}>
              <Link className={styles.linkd} to="/blog">
                BLOG
              </Link>
            </li>
            {/* -------------------------- */}
            <li className={styles.flex__props}>
              <Link className={styles.linkd} to="/contact">
                CONTACT US
              </Link>
            </li>
          </ul>
          <div className={styles.navbar__menu}>
            <Button
              className={styles.holdertext__menu}
              color="inherit"
              onClick={menuClicked}
            >
              <MenuIcon />
            </Button>
          </div>
        </div>
      </Toolbar>

      {/* ------------------------------ */}
      <div className={`${styles.menu__container}`}>
        <div
          className={`${styles.buttonholder__menu}  ${menu_clicked && styles.menu__slider
            }`}
        >
          <Button onClick={homeClickHandler} className={styles.holdertext} color="inherit">
            Home
          </Button>
          <Button onClick={productClickHandler} className={styles.holdertext} color="inherit">
            Product
          </Button>
          <Button onClick={useCaseClickHandler} className={styles.holdertext} color="inherit">
            UseCase
          </Button>
        </div>
      </div>
      {/* ------------------------------ */}
    </AppBar>
  );
};
