import React, { lazy, Suspense, useEffect } from 'react'
import styles from './Partners.module.css';
import { useLocation, useNavigate } from 'react-router-dom';
// import Revenue from '../../components/Partners/Revenue Sources/Revenue';
// import SupportThetalabs from '../../components/Partners/Support from Thetalabs/SupportThetalabs';
// import HowItWork from '../../components/Partners/How Does it work/HowItWork';
import { Maintenance } from '../../components/Maintenance/Maintenance';
import { ChatState } from '../../context/Context';
import Loading from '../../components/Loading/Loading';

const Revenue = lazy(() => import("../../components/Partners/Revenue Sources/Revenue"));
const SupportThetalabs = lazy(() => import("../../components/Partners/Support from Thetalabs/SupportThetalabs"));
const HowItWork = lazy(() => import("../../components/Partners/How Does it work/HowItWork"));


export const Partners = () => {
  const location = useLocation();
  const { containerRef, underMaintenance, setUnderMaintenance } = ChatState();
  // which components are making horizontal scroll appear on screen
  // team, usp, 
  useEffect(() => {
    const resizeListener = () => {
      const width = window.innerWidth;
      const height = window.innerHeight;
      if (width < 1000 || width < height + 100) {
        setUnderMaintenance(true);
      } else if (width > 2045) {
        setUnderMaintenance(true);
      } else {
        setUnderMaintenance(false);
      }
    }

    resizeListener()
    window.addEventListener("resize", resizeListener);
    return () => window.removeEventListener("resize", resizeListener);
  }, [])
  
  const Navigate = useNavigate();

  const goBack = () => {
    Navigate({ pathname: "/" });
  };
  useEffect(() => {    
    window.scrollTo({
      top: 0,
      behavior: 'instant'
    });
  }, [location])
  return (
    <>
      {underMaintenance && <Maintenance />}
      {!underMaintenance &&
        <Suspense fallback={<Loading />}>
          <div className={styles.Partners__container}>

            {/* --------- Title -------------- */}
            <div className={styles.Title__container}>
              <div className={styles.Title}>
                Why to be our<br /> PARTNER?
              </div>

              {/* --------- Go Back Button ------------- */}
              <button type="submit" onClick={goBack} className={styles.custom__back__button}>
                <img draggable={false} src={`${process.env.PUBLIC_URL}/Additionals/back.png`} alt="back button" />
              </button>
            </div>

            {/* ---------- Title Description ------------ */}
            <div className={styles.TitleDescription__container}>
              <div className={styles.TitleDescription__capsule}>
                <p className={styles.TitleDescription}>Become a proud partner and start the Primary Care Clinic with minimum effort and maximize the Earning Potential.</p>
              </div>
            </div>

            {/* ---------- Revenue Sources --------------- */}
            <Revenue />

            {/* ---------- Support from Thetalabs -------- */}
            <SupportThetalabs />

            {/* ---------- How does it work -------------- */}
            <HowItWork />
          </div>
        </Suspense>}
    </>
  )
}
